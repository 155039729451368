import React,{useState,useEffect} from "react";
import axios from 'axios'; // Axios for HTTP requests
import {Helmet} from "react-helmet";
import {Navigate, Route, Routes} from "react-router-dom";
import BoneLayoutDefault from "./components/layout/Layout";
import {Home} from "./containers/Home";
import {About} from "./containers/About";
import {HelpfulResources} from "./containers/HelpfulResources";
import {TermsConditions} from "./containers/TermsConditions";
import {PrivacyPolicy} from "./containers/PrivacyPolicy";
import {FAQ} from "./containers/FAQ";
import {ContactUs} from "./containers/ContactUs";
import {Areas} from "./containers/Areas";
import {SingleArea} from "./containers/areas/SingleArea";
import {Blogs} from "./containers/Blogs";
import {SingleBlog} from "./containers/SingleBlog";
import {Testimonials} from "./containers/Testimonials";



import {Services} from "./containers/Services";

import {Eviction} from "./containers/services/Eviction";
import {StartEviction} from "./containers/services/StartEviction";

import {Probate} from "./containers/services/Probate";
import {StartProbate} from "./containers/services/StartProbate";

import {Deed} from "./containers/services/Deed";
import {StartDeed} from "./containers/services/StartDeed";

import {Divorce} from "./containers/services/Divorce";
import {StartDivorce} from "./containers/services/StartDivorce";

import {SmallClaims} from "./containers/services/SmallClaims";
import {StartSmallClaims} from "./containers/services/StartSmallClaims";

import {LivingTrust} from "./containers/services/LivingTrust";
import {StartLivingTrust} from "./containers/services/StartLivingTrust";

import {ChildCustody} from "./containers/services/ChildCustody";
import {Fourzerofour} from "./containers/404";
import {StartChildCustody} from "./containers/services/StartChildCustody";




const routes = [
    
    {
        path: "/",
        component: Home,
        className: "home-layout page-wrapper",
        title: "LDA PRO LEGAL | Start Eviction, Probate, Living Trust, Deed Transfer Online",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "home"
    },
    {
        path: "/about-us",
        component: About,
        className: "home-layout page-wrapper",
        title: "About Us | Start Eviction, Probate, Living Trust, Deed Transfer Online",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'About Us'
    },
    {
        path: "/services",
        component: Services,
        className: "home-layout page-wrapper",
        title: "Legal Document Assistance Services | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Services'
    },
    {
        path: "/services/eviction",
        component: Eviction,
        className: "home-layout page-wrapper",
        title: "Legal Document Assistance Services | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "service",
        pageTitle:'Eviction Services'
    },
    {
        path: "/services/eviction/start-eviction-online",
        component: StartEviction,
        className: "home-layout page-wrapper",
        title: "Start Eviction Online | LDA PRO LEGAL",
        description: "Providing affordable and best Legal Document Assistance Services in California and Surrounding Counties. Services like, Probate, Eviction, Deed Transfer and Living Trust or Estate Planning.",
        layout: true,
        type: "page",
        pageTitle:'Start EvictionOnline'
    },
    {
        path: "/services/probate",
        component: Probate,
        className: "home-layout page-wrapper",
        title: "Legal Document Assistance Services | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "service",
        pageTitle:'probate Services'
    },
    {
        path: "/services/probate/start-probate-online",
        component: StartProbate,
        className: "home-layout page-wrapper",
        title: "Start Probate Online | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Start Probate Online'
    },
    {
        path: "/services/divorce",
        component: Divorce,
        className: "home-layout page-wrapper",
        title: "Legal Document Assistance Services | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "service",
        pageTitle:'Divorce Services'
    },
    {
        path: "/services/divorce/start-divorce-filing-online",
        component: StartDivorce,
        className: "home-layout page-wrapper",
        title: "Start Divorce Online | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Start Divorce Online'
    },
    {
        path: "/services/deed",
        component: Deed,
        className: "home-layout page-wrapper",
        title: "Legal Document Assistance Services | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "service",
        pageTitle:'Deed Services'
    },
    {
        path: "/services/deed-services/start-deed-services-online",
        component: StartDeed,
        className: "home-layout page-wrapper",
        title: "Start Deed Online | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Start Deed Online'
    },
    {
        path: "/services/living-trust",
        component: LivingTrust,
        className: "home-layout page-wrapper",
        title: "Legal Document Assistance Services | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "service",
        pageTitle:'Living Trust Services'
    },
    {
        path: "/services/living-trust/start-living-trust-online",
        component: StartLivingTrust,
        className: "home-layout page-wrapper",
        title: "Start Living Trust Online | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Start Living Trust Online'
    },
    
    {
        path: "/contact-us",
        component: ContactUs,
        className: "home-layout page-wrapper",
        title: "Contact Us | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'ContactUs'
    },
    {
        path: "/faq",
        component: FAQ,
        className: "home-layout page-wrapper",
        title: "FAQ | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'FAQ'
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy,
        className: "home-layout page-wrapper",
        title: "Privacy Policy | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Privacy Policy'
    },
    {
        path: "/terms-and-conditions",
        component: TermsConditions,
        className: "home-layout page-wrapper",
        title: "Terms and Conditions | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Terms and Conditions'
    },
    {
        path: "/helpful-resources",
        component: HelpfulResources,
        className: "home-layout page-wrapper",
        title: "Helpful Resources | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Helpful Resources'
    },
    {
        path: "/404",
        component: Fourzerofour,
        className: "page-wrapper",
        title: "Page Not Found | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "404",
        pageTitle:'404'
    },
    {
        path: "/areas",
        component: Areas,
        className: "page-wrapper",
        title: "All Areas | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'All Areas'
    },
    {
        path: "/areas/sacramento",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Sacramento | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Sacramento. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Sacramento'
    },
    {
        path: "/areas/placer",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Placer | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Placer. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Placer'
    },
    {
        path: "/areas/yolo",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Yolo | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Yolo. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Yolo'
    },
    {
        path: "/areas/san-joaquin",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in San Joaquin | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in San Joaquin. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'San Joaquin'
    },
    {
        path: "/areas/solano",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Solano | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Solano. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Solano'
    },
    {
        path: "/areas/alameda",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Alameda | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Alameda. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Alameda'
    },
    {
        path: "/areas/butte",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Butte | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Butte. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Butte'
    },
    {
        path: "/areas/amador",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Amador | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Amador. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Amador'
    },
    {
        path: "/areas/contra-costa",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Contra Costa | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Contra Costa. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Contra Costa'
    },
    {
        path: "/areas/el-dorado",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in El Dorado | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in El Dorado. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'El Dorado'
    },
    {
        path: "/areas/marin",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Marin | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Marin. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Marin'
    },
    {
        path: "/areas/napa",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Napa | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Napa. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Napa'
    },
    {
        path: "/areas/san-fransisco",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in San Fransisco | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in San Fransisco. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'San Fransisco'
    },
    {
        path: "/areas/san-mateo",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in San Mateo | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in San Mateo. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'San Mateo'
    },
    {
        path: "/areas/santa-clara",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Santa Clara | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Santa Clara. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Santa Clara'
    },
    {
        path: "/areas/shasta",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Shasta | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Shasta. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Shasta'
    },
    {
        path: "/areas/sutter",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Sutter | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Sutter. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Sutter'
    },
    {
        path: "/areas/yuba",
        component: SingleArea,
        className: "page-wrapper",
        title: "Legal Document Assistance in Yuba | LDA PRO LEGAL",
        description: "Get expert legal document services and paralegal assistance in Yuba. We offer services like probate, divorce paperwork, eviction assistance, and more.",
        layout: true,
        type: "page",
        pageTitle:'Yuba'
    },
    {
        path: "/blogs",
        component: Blogs,
        className: "page-wrapper",
        title: "All Blogs | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'All Blogs'
    },
    {
        path: "/:slug",
        component: SingleBlog,
        className: "page-wrapper",
        title: "Blog Not Found | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Blog Not Found'
    },
    {
        path: "/testimonials",
        component: Testimonials,
        className: "page-wrapper",
        title: "Client Testimonials | LDA PRO LEGAL",
        description: "LDA PRO LEGAL is an experienced team of registered legal document assistants specializing in Eviction, Probate, Living Trust, and Deed Transfers.",
        layout: true,
        type: "page",
        pageTitle:'Testimonials'
    }
];
type Area = {
    slug: string;
    seo_title: string;
    seo_description: string;
    seo_keywords: string;
    name: string;
    featured_image: string;
};

export const App = (): React.ReactElement => {
    const [posts, setPosts] = useState<Area[]>([]); // Explicitly set type to Area[]
    const [areas, setAreas] = useState<Area[]>([]); // Separate state for areas with type

    // Fetch data on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://server.ldaprolegal.com/api/v2/get-all-areas');
                // Make sure response.data is an array before setting it to posts
                if (Array.isArray(response.data)) {
                    setAreas(response.data);
                } else {
                    console.error("Expected an array but got:", response.data);
                }
            } catch (error) {
                console.error(error); // Log error if request fails
            }
        };

        fetchData();

        const fetchBlogs = async () => {
            try {
                const response = await axios.get('https://server.ldaprolegal.com/api/v2/get-all-posts');
                // Make sure response.data is an array before setting it to posts
                if (Array.isArray(response.data)) {
                    setPosts(response.data);
                } else {
                    console.error("Expected an array but got:", response.data);
                }
            } catch (error) {
                console.error(error); // Log error if request fails
            }
        };

        fetchBlogs();
    }, []);

    // Set areas whenever posts update
    useEffect(() => {
        setAreas(areas);
    }, [areas]);
    // Set areas whenever posts update
    useEffect(() => {
        setPosts(posts);
    }, [posts]);

    return (
        <Routes>
            {routes.map(({ path, component: Component, title, description, className, layout, type, pageTitle }, index) => (
                <Route
                    key={index}
                    path={path}
                    element={
                        <>
                            {title && (
                                <Helmet>
                                    <title>{title}</title>
                                    <link rel="canonical" href={`https://ldaprolegal.com${path}`} />
                                    <meta name="description" content={description} />
                                    <meta property="og:title" content={title} />
                                    <meta property="og:description" content={description} />
                                    <meta name="twitter:title" content={title} />
                                    <meta name="twitter:description" content={description} />
                                </Helmet>
                            )}
                            {layout ? (
                                <BoneLayoutDefault type={type} pageTitle={pageTitle} className={className}>
                                    <Component />
                                </BoneLayoutDefault>
                            ) : (
                                <Component />
                            )}
                        </>
                    }
                />
            ))}
            {(areas || []).map((area, index) => ( // Ensure areas is an array
                <Route
                    key={index}
                    path={`/areas/${area.slug}`}
                    element={
                        <>
                            <Helmet>
                                <title>{area.seo_title}</title>
                                <link rel="canonical" href={`https://ldaprolegal.com/area/${area.slug}`} />
                                <meta name="description" content={area.seo_description} />
                                <meta name="keywords" content={area.seo_keywords}></meta>
                                <meta name="robots" content="index, follow"></meta>
                                <meta name="author" content="LDA PRO LEGAL"></meta>

                                <meta property="og:title" content={area.seo_title} />
                                <meta property="og:description" content={area.seo_description} />
                                <meta property="og:url" content={`https://ldaprolegal.com/area/${area.slug}`}></meta>
                                <meta property="og:image" content={require("./assets/images/common/lda-pro-new-logo.webp")}></meta>
                                <meta property="og:type" content="website"></meta>
                                <meta property="og:site_name" content="LDA PRO LEGAL"></meta>
                            </Helmet>
                            <BoneLayoutDefault type="page" className="page-wrapper" pageTitle={area.name}>
                                <SingleArea />
                            </BoneLayoutDefault>
                        </>
                    }
                />
            ))}
            {(posts || []).map((post, index) => ( // Ensure areas is an array
                <Route
                    key={index}
                    path={`/${post.slug}`}
                    element={
                        <>
                            <Helmet>
                                <title>{post.seo_title}</title>
                                <link rel="canonical" href={`https://ldaprolegal.com/${post.slug}`} />
                                <meta name="description" content={post.seo_description} />
                                <meta name="keywords" content={post.seo_keywords}></meta>
                                <meta name="robots" content="index, follow"></meta>
                                <meta name="author" content="LDA PRO LEGAL"></meta>

                                <meta property="og:title" content={post.seo_title} />
                                <meta property="og:description" content={post.seo_description} />
                                <meta property="og:url" content={`https://ldaprolegal.com/${post.slug}`}></meta>
                                <meta property="og:image" content={post.featured_image}></meta>
                                <meta property="og:type" content="website"></meta>
                                <meta property="og:site_name" content="LDA PRO LEGAL"></meta>
                            </Helmet>
                            <BoneLayoutDefault type="page" className="page-wrapper" pageTitle={post.name}>
                                <SingleBlog />
                            </BoneLayoutDefault>
                        </>
                    }
                />
            ))}
            <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
    );
};

