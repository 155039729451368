import React from "react";
import {Link} from 'react-router-dom'
import Swal from "sweetalert2";


class Footer extends React.Component {
	constructor(props) {
			super(props);
			this.state = {
				Name: "",
				Phone: "",
				Email: "",
				Message: ""
			};
		}
	
		handleChange = (e) => {
			this.setState({ [e.target.name]: e.target.value });
		};
	
		handleSubmit = async (e) => {
			e.preventDefault();
			
			try {
				const response = await fetch("https://server.ldaprolegal.com/api/v2/form-submit.php", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(this.state),
				});
				const result = await response.json();
				if (result.success) {
					Swal.fire({
						icon: "success",
						title: "Success!",
						text: "Your form has been submitted successfully.",
					});
				} else {
					alert("Failed to send message. Please try again.");
				}
			} catch (error) {
				console.error("Error:", error);
				alert("An error occurred.");
			}
	};
	render() {
		return (
			<>
			<div class="container-fluid" id="newsletter-section">
				<div class="container">
					<div class="row">
						<div class="col-xl-12">
							<article class="newsletter-content_part">
								<h2>Newsletters</h2>
								<div class="line2"></div>
								<h3>Hiring For New Interns</h3>
							</article>
							<div class="col-xl-8">
								<div class="newsletter_section">
									<form>
										<div class="form-group">
											<input type="email" class="form-control input-field" placeholder="Enter email"/>
											<button type="submit" class="btn btn-denger">Subscribe</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
				<div class="mobile-call">
					<a href="tel:1-844-453-2776" class="call"><i class="fa fa-phone"></i> Call</a>
					<a href="mailto:Contact@Ldaprolegal.Com" class="message"><i class="fa fa-envelope"></i> Email</a>
				</div>
				<div class="mobile-call">
					<a href="tel:1-844-453-2776" class="call"><i class="fa fa-phone"></i> Call</a>
					<a href="mailto:Contact@Ldaprolegal.Com" class="message"><i class="fa fa-envelope"></i> Email</a>
				</div>
				<footer class="main-footer">
					<div class="widgets-section">
						<div class="auto-container">
							<div class="row clearfix">
								<div class="footer-column col-xl-3 col-md-6 col-sm-6 col-xs-12">
									<div class="footer-widget logo-widget">
										<div class="footer-logo"><Link to="/"><img src={require("../../assets/images/common/footer-lda-white-logo.webp")} alt="LDA PRO Legal"/></Link></div>
										<p className="mt-3 mb-4 text-white">
										We prepare, file, and serve legal documents such as: Eviction, Probate, Living Trust, Divorce, and more.
										</p>
										
										<div class="widget-content">
											<ul class="contact-info mt-2">
												<li><div class="icon"><span class="flaticon-placeholder"></span></div><span class="title">Locate us on:</span>3550 Watt Ave., Suite 140, Sacramento, CA 95821</li>
												<li><div class="icon"><span class="flaticon-email"></span></div><span class="title">Send us on:</span><a href="mailto:contact@ldaprolegal.com">contact@ldaprolegal.com</a></li>
												<li><div class="icon"><span class="flaticon-phone-call"></span></div><span class="title">Main Office:</span><a href="tel:(916) 620-2446">(916) 620-2446</a></li>
												<li><div class="icon"><span class="flaticon-phone-call"></span></div><span class="title">Toll Free:</span><a href="tel:1-844-453-2776">1-844-453-2776</a></li>
												<li><div class="icon"><span class="flaticon-clock"></span></div><span class="title">Monday through Friday:</span>8:30am to 5:00pm</li>
											</ul>

											<ul class="social-box footer">
												<li><a href="https://www.facebook.com/LdaProLegal/" class="fa fa-facebook" target="_Blank" rel="noreferrer"></a></li>
												<li><a href="https://www.instagram.com/ldapro_legal/" class="fa fa-instagram" target="_Blank" rel="noreferrer"></a></li>
												<li><a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x809ad794cb08988b:0xf26331dc35857cf?source=g.page.m" class="fa fa-google" target="_Blank" rel="noreferrer"></a></li>
												<li><a href="https://www.yelp.com/biz/lda-pro-legal-document-assistants-sacramento-3" class="fa fa-yelp" target="_Blank" rel="noreferrer"></a></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="footer-column col-xl-2 col-md-6 col-sm-6 col-xs-12">
						            <div class="footer-widget links-widget">
										<h2 className="color-white">Our Services</h2>
										<ul class="services-list">
											<li><Link to="/services/eviction/">Eviction</Link></li>
											<li><Link to="/services/divorce">Divorce</Link></li>
											<li><Link to="/services/probate/">Probate</Link></li>
											<li><Link to="/services/living-trust/">Living Trust</Link></li>
											<li><Link to="/services/deed">Deed Transfer</Link></li>
										</ul>
									</div>
								</div>
								<div class="footer-column col-xl-3 col-sm-6 col-xs-12 column">
						            <div class="footer-widget links-widget">
										<h2 className="color-white">Areas We Serve</h2>
										<ul class="services-list areas-list">
											<li><Link to="/areas/sacramento/">Sacramento</Link></li>
											<li><Link to="/areas/placer/">Placer</Link></li>
											<li><Link to="/areas/yolo/">Yolo</Link></li>
											<li><Link to="/areas/san-joaquin/">San Joaquin</Link></li>
											<li><Link to="/areas/solano/">Solano</Link></li>
											<li><Link to="/areas/butte/">Butte</Link></li>
											<li><Link to="/areas/amador/">Amador</Link></li>
											<li><Link to="/areas/yuba/">Yuba</Link></li>
											<li><Link to="/areas/el-dorado/">El Dorado</Link></li>
											<li><Link to="/areas/sutter/ ">Sutter</Link></li>
											<li><Link to="/areas/alameda/">Alameda</Link></li>
											<li><Link to="/areas/contra-costa/">Contra Costa</Link></li>
											<li><Link to="/areas/marin/">Marin</Link></li>
											<li><Link to="/areas/napa/">Napa</Link></li>
											<li><Link to="/areas/san-fransisco/">San Francisco</Link></li>
											<li><Link to="/areas/">Stanislaus</Link></li>
										</ul>
										<ul class="services-list">
											<li><Link to="/areas">Serving all counties in Northern California</Link></li>
										</ul>
                        			</div>
                    			</div>
								<div class="footer-column col-xl-3 col-sm-6 col-xs-12 column">
						            <div class="footer-widget links-widget">
										<h2 className="color-white">Send us a Message</h2>
										<div class="widget-content">
											<div class="footer-form">
												<div class="wpcf7 no-js" id="wpcf7-f8621-o2" lang="en-US" dir="ltr">
													<div class="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true"></p> <ul></ul></div>
														<form onSubmit={this.handleSubmit}>
															<input type="hidden" name="form-name" value="footercontact" />
															<div class="form-group">
																<input size="40" maxlength="400" class="wpcf7-form-control" placeholder="Name *" type="text" name="Name"  value={this.state.Name} onChange={this.handleChange} required/>
															</div>
															<div class="form-group">
															<input size="40" maxlength="400" class="wpcf7-form-control" placeholder="Email *" type="email" name="Email" value={this.state.Email} onChange={this.handleChange} required />
															<input size="40" maxlength="400" class="wpcf7-form-control" placeholder="Phone *" type="hidden" name="Phone" value={this.state.Phone} onChange={this.handleChange}/>
															</div>
															<div class="form-group">
															<input size="40" maxlength="400" class="wpcf7-form-control" placeholder="Message *" type="text" name="Message" value={this.state.Message} onChange={this.handleChange} required />

															</div>
															<div class="form-group">
																<button class="theme-btn btn-style-three" type="submit">Submit Now</button>
															</div>
														</form>
													</div>
												</div>
											</div>
                        				</div>
                    				</div>
									<div class="col-sm-12 text-center footer-first-section">
										<p style={{color:'#fff'}}><span>Registered Legal Document Assistants, LDAs, are not attorneys. We cannot provide legal advice or represent you in court. We prepare documents at the client’s direction. If you need legal representation in court, we can refer you to a cooperating attorney.<br />LDA and Associates d.b.a. LDA PRO LEGAL</span></p>
										<p style={{color:'#fff'}}><span>LDA # 2016-01 UDA# 2017-01<br />Sacramento County</span></p>
									</div>
									<div class="footer-second-content-part">
										<div class="col-sm-12 text-center">
											<p style={{color:'#fff'}}><span>Registered Unlawful Detainer Assistants (UDAs) are authorized to render assistance or advice in the prosecution of an unlawful detainer claim or action. Bus. &amp; Prof. Code Section 6400 (a) We are not attorneys and we can not represent you in court. Should you require an attorney to represent you in court, one of our cooperating attorneys can represent you for a discounted fee.</span></p>
											<p style={{color:'#fff'}}><span>UDA # 2017-01<br />Sacramento County</span></p>
											<a href="https://www.bbb.org/us/ca/sacramento/profile/legal-services/lda-pro-legal-1156-90035500/#sealclick" target="_blank" rel="noreferrer"><img src="https://seal-necal.bbb.org/seals/blue-seal-293-61-whitetxt-bbb-90035500.png" style={{border: "0;"}} alt="LDA Pro Legal BBB Business Review" /> BBB</a>
										</div>	
									</div>	
                				</div>
            				</div>
        				</div>
						<div class="footer-bottom">
							<div class="auto-container">
								<div class="row clearfix">
									<div class="column col-md-6 col-sm-12 col-xs-12">
										<div class="copyright">©2009 - 2025 <Link to="/">LDA PRO LEGAL.</Link> All Rights Reserved </div>
									</div>
									<div class="nav-column col-md-6 col-sm-12 col-xs-12">
										<ul class="footer-nav">
											<li><Link to="/privacy-policy/">Privacy Policy</Link></li>
											<li><Link to="/terms-and-conditions/">Terms and Conditions</Link></li>
											<li><Link to="/helpful-resources/">Helpful Resources</Link></li>
											<li><Link to="/sitemap.xml">Site Map</Link></li>
										</ul>
									</div>
									<p className="copyright" style={{marginBottom:"0",textAlign:"center",width:"100%"}}>Website by <a href="https://softmindersinc.com" target="_Blank" rel="noreferrer">Soft Minders</a> | Powered by React</p>
								</div>
							</div>
						</div>
					
    			</footer>
				<div class="search-popup">
					<button class="close-search style-two"><span class="flaticon-cancel-1"></span></button>
					<button class="close-search"><span class="flaticon-up-arrow"></span></button>
					<form method="post" action="/">
						<div class="form-group">
							<input type="search" name="s" value="" placeholder="Search Here" required=""/>
							<button type="submit"><i class="fa fa-search"></i></button>
						</div>
					</form>
				</div>
				<div class="scroll-to-top scroll-to-target" data-target="html"><span class="fa fa-arrow-up"></span></div>
			</>
		);
	}
}

export default Footer;
